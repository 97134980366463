import React, { useEffect, useState } from "react";
import { Tabs, message } from "antd"
import { Document, Page, pdfjs } from 'react-pdf'
import { setPageTitle, sampleRange } from "../common/tools";
import "../css/viewer.css"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

const pageTitle = "查看合同"

const contractInfo = [
    {
        fileId: "1",
        fileName: "租赁合同1.pdf",
        fileUrl: "https://mirc-service.oss-cn-shenzhen.aliyuncs.com/redirect/DG231.pdf"
    },
    {
        fileId: "2",
        fileName: "租赁合同2.pdf",
        fileUrl: "https://mirc-service.oss-cn-shenzhen.aliyuncs.com/redirect/%E5%90%88%E5%90%8C.pdf"
    },
    {
        fileId: "3",
        fileName: "租赁合同3.pdf",
        fileUrl: "https://mirc-service.oss-cn-shenzhen.aliyuncs.com/redirect/DG231.pdf"
    },
]


function Viewer({ contractNo, accessToken }) {
    useEffect(() => { setPageTitle(pageTitle) }, []);

    function PdfDocument({ fileUrl }) {
        const [pdfPages, setPdfPages] = useState(<Page />)

        function createPage(pageNumber) {
            return (
                <Page
                    pageNumber={pageNumber}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    width={window.innerWidth}
                />
            )
        }


        function onDocumentLoadSuccess({ numPages }) {
            if (numPages) {
                let pages = sampleRange(numPages).map(
                    (pageNumber) => {
                        return createPage(pageNumber)
                    }
                )
                setPdfPages(pages)
            }
        }


        return (
            <Document
                file={fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {pdfPages}
            </Document>
        )
    }

    function PdfContainer({ fileName, fileUrl }) {
        return (
            <div className="pdf-container">
                <div className="pdf-name-box">
                    <div className="pdf-name"> {fileName} </div>
                </div>
                <div className="pdf-pages-container">
                    <PdfDocument fileUrl={fileUrl}></PdfDocument>
                </div>
            </div>
        )
    }

    function TabTitle({ title }) { // 标签页标题
        return <div className="pdf-tab-title">{title}</div>
    }

    function createPdfTab(key, title, pdfName, pdfUrl) { // 标签页TabItems
        return {
            key: key,
            label: <TabTitle title={title} />,
            children: <PdfContainer fileName={pdfName} fileUrl={pdfUrl} />
        }
    }


    let tabItems = contractInfo.map((value, index) => {
        const key = value.fileId
        const title = `文档${index + 1}`
        const pdfName = value.fileName
        const pdfUrl = value.fileUrl
        return createPdfTab(key, title, pdfName, pdfUrl)
    })



    return (
        <div className="page-container">
            <Tabs items={tabItems}></Tabs>
        </div>
    )
}

export default Viewer;