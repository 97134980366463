function setPageTitle(title) {
    document.title = title
}

function getQueryVariable() {
    var data = {}
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    vars.forEach(item => {
        var pair = item.split("=");
        data[pair[0]] = pair[1];
    })
    return data
};

function sampleRange(num) {
    return Array.from(Array(num), (_, index) => index + 1)
}

function disabelScrollUpdate() {
    document.body.addEventListener('touchmove', (e) => {
        console.log(e)
        e.preventDefault();
    }, { passive: false })
}



export {
    sampleRange,
    setPageTitle,
    getQueryVariable,
    disabelScrollUpdate
}