import React from 'react';
import ReactDOM from 'react-dom/client';
import Viewer from './page/viewer';
import { getQueryVariable } from './common/tools';
import "./css/html.css"

const { contractNo, accessToken } = getQueryVariable()
console.log(contractNo, accessToken)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Viewer contractNo={contractNo} accessToken={accessToken} />
  </React.StrictMode>
);